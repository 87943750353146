body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  overflow: auto;
  font-variant: normal !important; -webkit-font-variant: normal !important; font-variant-ligatures: none !important; -webkit-font-variant-ligatures: none !important;
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.load-placeholder {
  animation: flashing 2s ease-in-out infinite;

  border-radius: 3px;
}

@keyframes flashing {
  0% {
    background-color: rgb(231, 242, 255);
  }
  50% {
    background-color: rgb(231, 231, 231);
  }
  100% {
    background-color: rgb(231, 242, 255);
  }
}
